import React, { useState } from "react";
import { Navbar, Nav, Container, Image } from "react-bootstrap";

import Header from "./layout/Header";
import ServicesSection from "./section/ServicesSection";
import GallerySection from "./section/GallerySection";
import BenefitSection from "./section/BenefitSection";
import PortfolioSection from "./section/PortfolioSection";
import LessonSection from "./section/LessonSection";
import ContactSection from "./section/ContactSection";
import LocationSection from "./section/LocationSection";
import OurStudentsSection from "./section/OurStudentsSection";
import Footer from "./layout/Footer";

import "./css/styles.css";

const App: React.FC = () => {
  const [expanded, setExpanded] = useState(false);

  const closeNavbar = () => {
    setExpanded(false);
  };

  return (
    <div className="App">
      <Navbar bg="light" expand="lg" fixed="top" expanded={expanded}>
        <Container>
          <Navbar.Brand href="#home" onClick={closeNavbar}>
            <Image
              src="/assets/img/hunAcousticLogo_01.png"
              alt="Hoon Acoustic"
              style={{ height: "35px" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : true)}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                href="#home"
                onClick={closeNavbar}
                className="nav-link-custom"
              >
                HOME
              </Nav.Link>
              <Nav.Link
                href="#services"
                onClick={closeNavbar}
                className="nav-link-custom"
              >
                SERVICES
              </Nav.Link>
              <Nav.Link
                href="#gallery"
                onClick={closeNavbar}
                className="nav-link-custom"
              >
                GALLERY
              </Nav.Link>
              <Nav.Link
                href="#lesson"
                onClick={closeNavbar}
                className="nav-link-custom"
              >
                LESSON
              </Nav.Link>
              <Nav.Link
                href="#portfolio"
                onClick={closeNavbar}
                className="nav-link-custom"
              >
                CONTENTS
              </Nav.Link>
              <Nav.Link
                href="#ourstudents"
                onClick={closeNavbar}
                className="nav-link-custom"
              >
                OUR STUDENTS
              </Nav.Link>
              <Nav.Link
                href="#contact"
                onClick={closeNavbar}
                className="nav-link-custom"
              >
                CONTACT
              </Nav.Link>
              <Nav.Link
                href="#location"
                onClick={closeNavbar}
                className="nav-link-custom"
              >
                LOCATION
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Header />
      <ServicesSection />
      <GallerySection />
      <BenefitSection />
      <LessonSection />
      <PortfolioSection />
      <OurStudentsSection />
      <ContactSection />
      <LocationSection />
      <Footer />
    </div>
  );
};

export default App;
