import React, { forwardRef } from "react";
import { Container, Row, Col, Carousel, Image } from "react-bootstrap";

import "../../src/css/styles.css";

interface GalleryItemProps {
  src: string;
  alt: string;
}

const GalleryItem: React.FC<GalleryItemProps> = ({ src, alt }) => (
  <Col md={4} className="mb-4">
    <Image className="d-block w-100 custom-image" src={src} alt={alt} />
  </Col>
);

interface GalleryRowProps {
  items: GalleryItemProps[];
}

const GalleryRow = forwardRef<HTMLDivElement, GalleryRowProps>(
  ({ items }, ref) => (
    <div ref={ref as React.RefObject<HTMLDivElement>}>
      <Row>
        {items.map((item, index) => (
          <GalleryItem key={index} src={item.src} alt={item.alt} />
        ))}
      </Row>
    </div>
  )
);

const GallerySection: React.FC = () => {
  const galleryItems = [
    [
      { src: "/assets/img/gallery_Photo_01.jpeg", alt: "gallery_photo_1" },
      { src: "/assets/img/gallery_Photo_02.jpeg", alt: "gallery_photo_2" },
      { src: "/assets/img/gallery_Photo_03.jpeg", alt: "gallery_photo_3" },
    ],
    [
      { src: "/assets/img/gallery_Photo_04.jpeg", alt: "gallery_photo_4" },
      { src: "/assets/img/gallery_Photo_05.jpeg", alt: "gallery_photo_5" },
      { src: "/assets/img/gallery_Photo_06.jpeg", alt: "gallery_photo_6" },
    ],
    [
      { src: "/assets/img/gallery_Photo_07.jpeg", alt: "gallery_photo_7" },
      { src: "/assets/img/gallery_Photo_08.jpeg", alt: "gallery_photo_8" },
      { src: "/assets/img/gallery_Photo_09.jpeg", alt: "gallery_photo_9" },
    ],
  ];

  return (
    <section className="page-section bg-light" id="gallery">
      <Container>
        <Row className="text-center mb-4">
          <Col>
            <h2 className="section-heading text-uppercase">Gallery</h2>
          </Col>
        </Row>
        <Carousel>
          {galleryItems.map((items, index) => (
            <Carousel.Item key={index}>
              <GalleryRow items={items} />
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </section>
  );
};

export default GallerySection;
