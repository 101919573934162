import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

const contacts = [
  {
    id: 1,
    src: "/assets/img/naver_consultation.png",
    alt: "contact_photo_1",
    url: "https://talk.naver.com/ct/w45uov?frm=mblog#nafullscreen",
  },
  {
    id: 2,
    src: "/assets/img/phone_consultation.png",
    alt: "contact_photo_2",
    url: "tel:031-714-7349",
  },
  {
    id: 3,
    src: "/assets/img/kakao_consultation.png",
    alt: "contact_photo_3",
    url: "http://pf.kakao.com/_quxfdj",
  },
];

const ContactSection: React.FC = () => {
  return (
    <section className="page-section" id="contact">
      <Container>
        <Row className="text-center mb-4">
          <Col>
            <h2 className="section-heading text-uppercase">Contact</h2>
          </Col>
        </Row>
        <Row>
          {contacts.map((contact) => (
            <Col key={contact.id} md={4} className="mb-4">
              <a
                href={contact.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Image
                  className="d-block w-100 custom-image"
                  style={{ height: "auto" }}
                  src={contact.src}
                  alt={contact.alt}
                />
              </a>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ContactSection;
