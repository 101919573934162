import React from "react";
import { Container, Row, Col, Dropdown, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

import "../../src/css/styles.css";

const Footer: React.FC = () => {
  return (
    <footer className="bg-dark text-white py-4">
      <Container>
        <Row className="text-center">
          <Col>
            <p>훈:어쿠스틱기타하우스</p>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <p style={{ fontSize: "14px" }}>
              경기 성남시 분당구 백현로144번길 29-1 1층,
              훈:어쿠스틱기타하우스음악교습소
            </p>
          </Col>
        </Row>
        <Row className="text-center my-3">
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <a
              href="https://blog.naver.com/hun_guitar"
              className="text-white mx-2"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src="/assets/img/naver_blog.png"
                style={{ width: "32px", height: "30px" }}
              />
            </a>
            <a
              href="https://www.instagram.com/hun_guitarrr/"
              className="text-white mx-2"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            <a
              href="https://www.youtube.com/@332joseph"
              className="text-white mx-2"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src="/assets/img/youtube.png"
                style={{ width: "32px", height: "28px" }}
              />
            </a>
            <div
              style={{
                width: "1px",
                height: "15px",
                backgroundColor: "white",
                borderRadius: "10px",
                margin: "0 10px 0 2px",
              }}
            ></div>
            <Dropdown data-bs-theme="dark">
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                variant="secondary"
                style={{ fontSize: "12px" }}
              >
                FamilySite
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href="https://alldaymusic.modoo.at/"
                  target="_blank"
                  rel="noreferrer"
                  className="custom-dropdown-item"
                  style={{ fontSize: "12px" }}
                >
                  올데이뮤직스튜디오
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
