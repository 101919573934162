import React from "react";
import { Container, Row, Col } from "react-bootstrap";

interface ServiceBoxProps {
  icon: string;
  title: string;
  text: string;
  backgroundImage: string;
}

const ServiceBox: React.FC<ServiceBoxProps> = ({
  icon,
  title,
  text,
  backgroundImage,
}) => {
  return (
    <div
      style={{
        border: "1px solid rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
        padding: "20px",
        height: "300px",
        background: `linear-gradient(
          to bottom,
          rgba(92, 77, 66, 0.1) 0%,
          rgba(92, 77, 66, 0.8) 100%
        ), url(${backgroundImage})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "scroll",
        backgroundSize: "cover",
      }}
    >
      <span className="fa-stack fa-4x">
        <i className="fas fa-circle fa-stack-2x text-primary"></i>
        <i className={`fas ${icon} fa-stack-1x fa-inverse`}></i>
      </span>
      <h4 className="my-3 text-white">{title}</h4>
      <p className="text-white">{text}</p>
    </div>
  );
};

const ServicesSection: React.FC = () => {
  return (
    <section id="services" className="page-section">
      <Container>
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Services</h2>
          <hr className="divider divider-light" />
          <h4 className="section-subheading text-muted mb-4">
            최고의 서비스를 제공합니다.
          </h4>
        </div>
        <Row className="text-center">
          <Col md={4} className="mb-4">
            <ServiceBox
              icon="fa-shopping-cart"
              title="체계적이고 전문적인 교육"
              text="철저하고 체계적인 교육 방식을 도입하여 선생님과 수강생 간의 긴밀한 관계를 통해 개인별 맞춤형 음악 교육을 제공"
              backgroundImage="/assets/img/hoonAcoustic_03.png"
            />
          </Col>
          <Col md={4} className="mb-4">
            <ServiceBox
              icon="fa-laptop"
              title="수준 높은 커리큘럼"
              text="학생의 수준을 고려한 맞춤식 커리큘럼이 가능하며 원장 직강으로 수업을 들을 수 있어 학생들의 실력 향상을 위한 체계적인 교육 제공"
              backgroundImage="/assets/img/guitar_02.png"
            />
          </Col>
          <Col md={4} className="mb-4">
            <ServiceBox
              icon="fa-lock"
              title="학생 중심의 교육 환경"
              text="학생과 선생님 간의 긍정적인 상호작용을 통해 학생 개개인의 음악적 잠재력을 최대한 이끌어내는 것을 목표로 함"
              backgroundImage="/assets/img/hunAcoustic_02.jpg"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ServicesSection;
