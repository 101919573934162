import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const Footer: React.FC = () => {
  return (
    <header
      id="home"
      className="masthead"
      style={{
        paddingTop: "10rem",
        paddingBottom: "calc(10rem - 4.5rem)",
        background: `linear-gradient(
        to bottom,
        rgba(92, 77, 66, 0.1) 0%,
        rgba(92, 77, 66, 0.8) 100%
      ), url("/assets/img/guitar_01.jpg")`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "scroll",
        backgroundSize: "cover",
      }}
    >
      <Container className="h-100">
        <Row className="h-100 align-items-center justify-content-center text-center">
          <Col lg={8} className="align-self-end">
            <h1 className="text-white font-weight-bold">
              훈:어쿠스틱기타하우스
            </h1>
            <hr className="divider divider-light" />
          </Col>
          <Col lg={8} className="align-self-baseline">
            <h5 className="text-white-75 mb-5">
              기타레슨 전문 훈:어쿠스틱기타하우스에서 체계적인 교육과 함께 기타
              연주 실력을 키워보세요!
            </h5>
            <Button
              variant="white-dark"
              size="lg"
              href="#services"
              style={{ fontWeight: "500" }}
            >
              바로가기
            </Button>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Footer;
