import React from "react";
import { Container, Row, Col, Carousel, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as fullStar } from "@fortawesome/free-solid-svg-icons";
import { faStarHalfAlt as halfStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as emptyStar } from "@fortawesome/free-regular-svg-icons";

interface ReviewItemProps {
  name: string;
  review: string;
  rating: number;
  imageUrl: string;
  shopItems: string[];
}

const ReviewItem: React.FC<ReviewItemProps> = ({
  name,
  review,
  rating,
  imageUrl,
  shopItems,
}) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 >= 0.5;
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

  return (
    <Col md={4} className="mb-4">
      <Card className="review-card">
        <Card.Body style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <div className="review-rating">
              {Array.from({ length: fullStars }, (_, i) => (
                <FontAwesomeIcon key={i} icon={fullStar} />
              ))}
              {hasHalfStar && <FontAwesomeIcon icon={halfStar} />}
              {Array.from({ length: emptyStars }, (_, i) => (
                <FontAwesomeIcon
                  key={i + fullStars + (hasHalfStar ? 1 : 0)}
                  icon={emptyStar}
                />
              ))}
            </div>
            <Card.Text className="review-text">{review}</Card.Text>
          </div>
          <div className="review-footer">
            <Card.Img
              variant="bottom"
              src={imageUrl}
              alt={name}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                marginRight: "10px",
                border: "0.1px solid #3f3f3f",
              }}
            />
            <div>
              <strong>{name}</strong>
              <ul className="shop-items-list">
                {shopItems.map((item, index) => (
                  <li key={index} className="shop-item">
                    #{item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

const OurStudentsSection: React.FC = () => {
  const reviews = [
    [
      {
        name: "최** 회원님",
        review:
          "막연하게 기타를 배우고 싶다는 생각과 1:1 교습이면 더 좋겠다는 생각으로 검색하다 등록한 곳인데, 1년 반 넘게 배우고 있습니다.\n\n훈 원장님의 가장 큰 장점은 제가 이해를 못 하고 잘 못 쳐도 절대 화를 내지 않으십니다. 크크크.. 저처럼 완전 생초보 취미 생들에겐 천사 같은 원장님이에요!!\n\n아직도 서투르고 기타 소리도 이상하게 날 때가 더 많지만, 내가 좋아했던 노래를 기타로 치면서 노래 부르다 보면 기타 배우기 잘했다는 생각이 들어요.",
        rating: 5,
        imageUrl: "/assets/img/puppy_01.jpg",
        shopItems: [
          "맞춤지도를 잘해줘요",
          "학생과 소통을 잘해요",
          "상담이 자세해요",
          "아늑해요",
          "가격이 합리적이에요",
        ],
      },
      {
        name: "임** 회원님",
        review:
          "오래전부터 기타를 배우고 싶었는데, 산책하다 발견한 학원이었어요!\n\n처음 상담 때부터 원장님이 정말 상냥하게 맞아주셔서 편안하게 상담받을 수 있었습니다. 무엇보다 수업하시는 중에도 저의 속도에 맞춰 알기 쉽게 설명해주시고, 친절하고 재치있으신 원장님 덕분에 즐겁게 배울 수 있었어요...\n\n가볍게 시작했는데, 매 시간이 기다려지고 수업이 정말 재밌어요. 혹시나 정자동 쪽에서 기타 교습 고민하신다면 여기 진짜 강력 추천드려요!!!",
        rating: 4,
        imageUrl: "/assets/img/minion.jpg",
        shopItems: [
          "선생님 실력이 좋아요",
          "학생과 소통을 잘해요",
          "맞춤지도를 잘해줘요",
          "가격이 합리적이에요",
          "시설이 깔끔해요",
        ],
      },
      {
        name: "Olive**회원님",
        review:
          "원장 선생님께서 매우 친절하시고 좋으세요!! ^.^***\n\n제가 궁금한 부분들을 아주 꼼꼼히 체크해서 알려주시고 좋은 곡들도 많이 추천해주셔서 기타치고 배우기가 훨씬 수월해졌습니다!\n\n그리고 일단 학원이 예뻐서 기분이 좋았습니다. 공영주차장이 바로 옆에 있어서 주차도 편했구요!! ㅎㅎ",
        rating: 4.5,
        imageUrl: "/assets/img/raccoon.jpeg",
        shopItems: [
          "주차하기 편해요",
          "연습공간이 잘 되어 있어요",
          "시설이 깔끔해요",
          "맞춤지도를 잘해줘요",
          "가격이 합리적이에요",
        ],
      },
    ],
    [
      {
        name: "최** 회원님",
        review:
          "정자동 기타학원 우연히 검색하다가 상담했는데...\n\n선생님께서 차분하게 상담 도와주셔서 기타 배우는데, 제수준에 맞춰서 잘 맞춰서 가르쳐주세요!\n\n무엇보다 공간이 주는 나무의 사계절을 볼 수 있어서 좋고, 기타를 배우면서 쉼을 누리면서 에너지가 생겨서 좋아요!",
        rating: 4,
        imageUrl: "/assets/img/lion.jpeg",
        shopItems: [
          "선생님 실력이 좋아요",
          "가격이 합리적이에요",
          "학생과 소통을 잘해요",
          "맞춤지도를 잘해줘요",
          "시설이 깔끔해요",
        ],
      },
      {
        name: "탱** 회원님",
        review:
          "수업받은 지 이제 3년 좀 더 된 것 같습니다. 제 눈높이에 맞춰서 자세하게 알려주셔서 좋아요!\n\n가끔 내려주시는 커피도 너무 좋고요...\n\n처음엔 통기타로 시작해서 요새는 클래식 기타 배우고 있는데, 다른 재미가 있는 것 같아요. 많은 분이 오셔서 저처럼 이런 즐거움을 얻어가셨음 좋겠습니다...!",
        rating: 4,
        imageUrl: "/assets/img/ducks.jpeg",
        shopItems: [
          "주차하기 편해요",
          "시설이 깔끔해요",
          "맞춤지도를 잘해줘요",
          "가격이 합리적이에요",
          "연습공간이 잘 되어 있어요",
        ],
      },
      {
        name: "rmatjq** 회원님",
        review:
          "김광석에 빠져서 통기타를 배우고 싶었는데, 정말 좋은 선생님을 만나서 재밌게 배우고 있습니다~~~\n\n노래까지 부르면서 기타 치는 그날이 올 때까지 화이팅해보려구요!!!🔥\n\n분당사시는 분들이라면! 기타학원 고민되신다면!\n\n이곳 적극 추천합니다아!!🙌",
        rating: 5,
        imageUrl: "/assets/img/puppy_02.jpeg",
        shopItems: [
          "선생님이 열정적이에요",
          "선생님 실력이 좋아요",
          "연습공간이 잘 되어있어요",
          "가격이 합리적이에요",
          "시설이 깔끔해요",
        ],
      },
    ],
  ];

  return (
    <section className="page-section bg-light" id="ourstudents">
      <Container>
        <Row className="text-center mb-4">
          <Col>
            <h2 className="section-heading text-uppercase">Our Students</h2>
          </Col>
        </Row>
        <Carousel interval={3000}>
          {reviews.map((group, index) => (
            <Carousel.Item key={index}>
              <Row>
                {group.map((review, idx) => (
                  <ReviewItem key={idx} {...review} />
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </section>
  );
};

export default OurStudentsSection;
