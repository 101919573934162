import React, { useState } from "react";
import { Modal, Button, Image } from "react-bootstrap";

import "../../src/css/styles.css";

interface PortfolioModalProps {
  id: string;
  title: string;
  subtitle: string;
  image: string;
  description: string;
  url: string;
}

const PortfolioModal: React.FC<PortfolioModalProps> = ({
  id,
  title,
  subtitle,
  image,
  description,
  url,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button
        className="portfolio-link"
        onClick={handleShow}
        style={{
          cursor: "pointer",
          border: "none",
          background: "none",
          padding: 0,
          width: "100%",
        }}
      >
        <div className="portfolio-hover">
          <div className="portfolio-hover-content">
            <i className="fas fa-plus fa-3x"></i>
          </div>
        </div>
        <Image className="img-fluid portfolio-item" src={image} alt={title} />
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="item-intro text-muted">{subtitle}</p>
          <p>{description}</p>
          <Image
            className="img-fluid d-block mx-auto mb-3 modal-image"
            src={image}
            alt={title}
          />
          <Button
            variant="secondary"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="w-100"
          >
            자세히 보기
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleClose}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PortfolioModal;
