import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import "../../src/css/styles.css";

interface LessonDetailsProps {
  title: string;
  details: string[];
}

const LessonDetails: React.FC<LessonDetailsProps> = ({ title, details }) => {
  const lastIndex = details.length - 1;

  return (
    <div>
      <h3>{title}</h3>
      {details.map((detail, index) => (
        <p
          key={index}
          className="custom-list"
          style={{
            marginBottom: index === lastIndex ? "10px" : "5px",
          }}
        >
          {detail}
        </p>
      ))}
    </div>
  );
};

interface LessonVideoProps {
  src: string;
  title: string;
  description: string;
}

const LessonVideo: React.FC<LessonVideoProps> = ({
  src,
  title,
  description,
}) => {
  return (
    <Col md={6}>
      <div className="lesson-video mb-4">
        <iframe
          src={src}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={title}
        ></iframe>
      </div>
    </Col>
  );
};

const LessonSection: React.FC = () => {
  const teacherDetails = [
    "숭실대 교회음악과",
    "서울장신대 예배찬양사역대학원",
    "훈:어쿠스틱기타하우스 원장",
  ];

  const projectDetails = [
    '첫번째 싱글 “아무것도 염려하지 말고” / 두번째 싱글 “여기에 모인 우리” / 세번째 싱글 “너 근심 걱정 말아라” / 네번째 싱글 “아름답다” 발표 / 다섯번째 싱글 "귤이 있어 감사" 발표',
  ];

  const albumDetails = [
    "개인음반 “사랑하면” 발표",
    '싱글 "그대가 그대라서" 발표',
    "다수의 음반 작편곡 / 연주 / 프로듀싱",
  ];

  const lessonMethods = [
    "1:1 개인레슨을 기본으로 하며, 레슨시간은 회당 50분입니다.",
    "그룹레슨의 경우 2 / 3 / 4인 구성으로 Class를 만들 수 있습니다. 단, 개인이 직접 그룹을 만들어야 합니다.",
    "레슨은 등록된 기간 안에 횟수 차감제로 진행됩니다.",
    "레슨은 주 1회를 기본으로 하되 본인의 필요에 따라 주 2회 / 3회도 가능합니다. 등록한 레슨기간이 수업횟수만큼 차감됩니다.",
    "스케줄은 선생님과 상의하여 고정 시간을 정하여 진행합니다. 부득이하게 스케줄을 바꾸거나 연기할 경우 하루 전날 연락하여 조정하시면 됩니다. 무단결석, 당일 취소는 수업횟수가 차감됩니다.",
  ];

  return (
    <section className="page-section bg-light" id="lesson">
      <Container>
        <Row className="text-center mb-4">
          <Col>
            <h2 className="section-heading text-uppercase">Lesson</h2>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Image
              src="/assets/img/hun_07.png"
              alt="Teacher"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "10px",
                marginBottom: "10px",
              }}
            />
          </Col>
          <Col md={6} style={{ alignContent: "center" }}>
            <div className="bg-light" style={{ padding: "10px" }}>
              <LessonDetails title="김훈섭" details={teacherDetails} />
              <LessonDetails
                title="훈제계란프로젝트"
                details={projectDetails}
              />
              <LessonDetails title="음반" details={albumDetails} />
            </div>
          </Col>
        </Row>
        <Row className="text-center">
          <Col md={4}>
            <div
              className="lesson-card"
              style={{
                textAlign: "center",
                marginBottom: "20px",
                backgroundColor: "white",
              }}
            >
              <h3>레슨대상</h3>
              <p style={{ margin: 0 }}>
                초 / 중 / 고등학생 / 주부
                <br />
                직장인 / 8세부터 100세까지
                <br />
                취미반 / 입시반 / 오디션반
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div
              className="lesson-card"
              style={{
                textAlign: "center",
                marginBottom: "20px",
                backgroundColor: "white",
              }}
            >
              <h3>레슨과목</h3>
              <p style={{ margin: 0 }}>
                통기타(어쿠스틱) / 클래식기타
                <br />
                일렉기타 / 우쿨렐레
                <br />
                작곡 / 음악이론 / 송라이팅
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div
              className="lesson-card"
              style={{
                textAlign: "center",
                marginBottom: "20px",
                backgroundColor: "white",
              }}
            >
              <h3>레슨방법</h3>
              <p style={{ margin: 0 }}>
                개인레슨
                <br />
                그룹레슨
                <br />
                출장레슨
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="lesson-card">
              <LessonDetails title="레슨안내" details={lessonMethods} />
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <LessonVideo
            src="https://www.youtube.com/embed/JfZmw1YOxZI"
            title="YouTube video 1"
            description="김훈섭 - 기타연주 [아름다운 마음들이 / 나의 발은 춤을 추며 / 항상 진실케]"
          />
          <LessonVideo
            src="https://www.youtube.com/embed/T2LJmamoa9I"
            title="YouTube video 2"
            description="김훈섭 - 기타연주 [서정소곡]"
          />
        </Row>
      </Container>
    </section>
  );
};

export default LessonSection;
