import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const benefits = [
  {
    id: 1,
    title: "정서적 혜택",
    description:
      "기타 연주는 스트레스를 줄이고, 기분을 개선하며, 자신감을 키울 수 있습니다.",
  },
  {
    id: 2,
    title: "인지 능력 향상",
    description:
      "연주 과정에서 읽기, 기억하기, 손과 눈의 협동을 통해 뇌를 활성화시키고 인지 능력을 향상시킵니다.",
  },
  {
    id: 3,
    title: "창의력 증진",
    description:
      "기타를 통해 자신만의 음악을 만들면서 창의력과 표현력이 발달합니다.",
  },
  {
    id: 4,
    title: "사회적 교류",
    description:
      "밴드 활동이나 공연을 통해 다른 사람들과 소통하고 협력하는 기회가 많아집니다.",
  },
  {
    id: 5,
    title: "인내심 및 집중력 향상",
    description:
      "기타 연주는 연습과 시간이 필요하며 이 과정에서 인내심과 집중력이 자연스럽게 향상됩니다.",
  },
];

const BenefitSection: React.FC = () => {
  return (
    <section className="page-section" id="benefit">
      <Container>
        <Row className="text-center mb-4">
          <Col>
            <h2 className="section-heading text-uppercase">
              우리는 함께 나아가요!
            </h2>
            <hr className="divider divider-light" />
            <h4 className="section-subheading text-muted mb-4">
              체계적인 레슨과 함께 기타 연주 실력을 키워보세요!
            </h4>
          </Col>
        </Row>
        <Row style={{ justifyContent: "space-between" }}>
          {benefits.map((benefit) => (
            <Col key={benefit.id} md={4} lg={2} className="mb-4">
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  padding: "20px",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h3
                  style={{
                    color: "orange",
                    fontSize: "30px",
                    fontWeight: "bold",
                    marginBottom: "20px",
                  }}
                >
                  {`0${benefit.id}`}
                </h3>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  {benefit.title}
                </h4>
                <p style={{ fontSize: "14px", color: "gray" }}>
                  {benefit.description}
                </p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default BenefitSection;
